.App {
    text-align: center;
}

#root {
    height: 100vh;
}

.dnh {
    font-size: large;
    font-weight: 600;
    gap: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    margin: 17px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    width: 100vw;
}

.h-full {
    height: 100%;
}


.logsFetch{
    color: black;
    margin-top: 15px;
    width: 140px;
    text-transform: uppercase;
    height: 30px;
    border: solid 0px;
    border-radius: 3px;
}

.loadingWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.link {
    margin: 8px 0 5px 0;
}

hr {
    margin: 0;
}

.logo {
    height: 50px;
    margin-right: 10px;
}
.wrapperTwo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 100vh;
    width: 100vw;
}

.colorBlack {
    color: black;
}

.balance {
    font-size: 1.7rem;
    font-weight: 600;
    border: solid 1px;
    border-color: rgb(106, 106, 106);
    width: 95vw;
    height: 10vh;
    display: flex;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.bottone {
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 150px;
    border-radius: 7px;
    background-color: rgb(49, 49, 49);
    border-color: rgb(106, 106, 106);
}

.bottone:hover {
    background-color: rgb(73, 73, 73);
}

.trade {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.prezzo {
    width: 50%;
    height: 20%;
}

.running {
    background-color: rgb(0, 91, 0);
    padding: 10px;
    border-radius: 10px;
}
.stopped {
    background-color: rgb(91, 0, 0);
    padding: 10px;
    border-radius: 10px;
}

.prezzi {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 95%;
    border: solid;
    border-radius: 10px;
    margin: 15px;
    padding: 3px;
    border-color: rgba(173, 173, 173, 0.4);
}

.nextAction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    gap: 5px;
    border-radius: 10px;
    margin: 15px;
    padding: 3px;
}

.m-login-form {
    background-color: #fff;
    padding: 40px 60px;
    box-shadow: 10px 7px 30px 10px rgba(255, 255, 255, 0.2);
    width: 30vw;
    border-radius: 10px;
}

@media screen and (max-width: 900px) {
    .m-login-form {
        width: 80vw;
    }
    .balance {
        font-size: 1.4rem;
        font-weight: 600;
        border: solid 1px;
        border-color: rgb(106, 106, 106);
        width: 95vw;
        height: 10vh;
        display: flex;
        padding: 20px;
        border-radius: 10px;
        margin-bottom: 15px;
        margin-top: 15px;
    }
    .price {
        font-size: small !important;
    }
}

@media screen and (min-width: 1100px) {
    .prezzi {
        justify-content: space-evenly;
    }
}

.price {
    display: flex;
    flex-direction: column;
    width: 100px;
}

.divider {
    width: 1px;
    height: 10vh;
    background-color: rgba(173, 173, 173, 0.4);
    margin: 3vw;
}

.show-error {
    opacity: 1;
    margin-top: 10px;
}

h3 {
    font-weight: 800;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: rgb(73, 73, 73);
}

input[type="text"],
input[type="password"] {
    display: block;
    margin-top: 15px;
    padding: 10px !important;
    border: none;
    border-bottom: 2px solid rgba(173, 173, 173, 0.4);
    padding: 5px 0;
    color: rgb(73, 73, 73);
    width: 100%;
}

input[type="submit"] {
    border: none;
    background-color: rgb(47, 47, 47);
    color: #fff;
    border-radius: 10px;
    padding: 10px 20px;
    margin-top: 25px;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
    text-transform: uppercase;
}

.srl {
    gap: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

p {
    font-size: 1.7rem;
    display: flex;
    justify-content: center;
    text-align: center;
}

@media screen and (max-height: 840px) {
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        /* height: auto; */
    }
}

.logs {
    margin-top: 15px;
    height: 90%;
    width: 100%;
    color: #fff;
    border-radius: 10px;
    overflow-y: auto;
    background-color: transparent;
    border: solid 0px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}

.green {
    background-color: green;
}

.legenda {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.legendaGreen {
    width: 20px;
    height: 20px;
    background-color: green;
}

.legendaRed {
    width: 20px;
    height: 20px;
    background-color: red;
}

.red {
    background-color: red;
}

.pre {
    font-size: smaller;
}

pre {
    background-color: transparent;
    color: #fff;
    border: none;
}

.bottoniDHN {
    display: flex;
    gap: 10px;
}

.infoTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 30px;
}

.chartWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    width: 90%;
    max-height: 500px;
    height: 100%;
}